import React from 'react';
import Container from '@material-ui/core/Container';
import { HomePageStyles } from '../../_styles/mui';
const useStyles = (theme) => HomePageStyles(theme)

const AboutComponent = () => {
    const classes = useStyles();
    return (
        <Container>
            <div className="split--section">
                <div className="split--section_wrap d-flex flex-md-row flex-column">
                    <div className="col-lg-5 px-0 mb-4 pb-2 pb-md-0 mb-md-0">
                        <div className="img-wrap d-flex p-lg-5 m-lg-4">
                            <img className="img-fluid max-100 mx-auto text-center" src={`/assets/images/about.jpeg`} alt="Vandan Narula" />
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="d-flex h-100">
                            <div className="my-auto">
                                <h1>
                                    About <span className={classes.primaryColorText}>Me</span>
                                </h1>
                                <p className={"mt-3"} style={{fontSize: "16px"}}>
                                    Hello, I am a senior at Monroe Township High School in NJ. “Making Friends and Making a Difference” is my guiding philosophy in life. I love to lead and get involved in various initiatives that can help our communities. 
                                    <br/>
                                    <br/>
                                    My passions are entrepreneurship, and everything related to Biology, Medicine and Genetics. I serve on my school boards of Distributive Education Clubs of America (DECA) and Health Occupation Students of America (HOSA).  My existing startup is focused on philanthropy and helping the homeless. I am also working on building a platform to allow people suffering from rare diseases to research, collaborate and seek specialist help.
                                    <br/>
                                    <br/>
                                    I love all sports, especially basketball and love reading science magazines and listening to sports podcasts. Harry Potter series and the Double Helix are my favorite books. I am an animal and nature lover and have two pets: Newton, a golden doodle and Fruity, a cockatiel.
                                    <br/>
                                    <br/>
                                </p>
                                <h4 className={"float-right"}>- Vandan Narula</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default AboutComponent