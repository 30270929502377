import React from 'react'

function NotVerified() {
    return (
        <React.Fragment>
            <div className="not-verified">
                <div className="img-wrap">
                    <img src={`/assets/images/not-found.svg`} className="img-fluid" alt="Not Verified"/>
                </div>
                <p>
                    Your account hasn’t been verified yet, you’ll be able to use the dashboard once you’ve been verified
                </p>
            </div>
        </React.Fragment>
    )
}

export default NotVerified