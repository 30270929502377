import ApiBuilder from "../_utils/_apiBuilder"
import { CONSTANTS } from "../_utils/_constants"
import { capitalize, convertNormalOptions, queryParamsToString } from "../_utils/_handlers"
import { LOADING, RESET_MODAL, RESET_RECORDS, SET_AUTH_SESSION, SET_RECORDS, SET_TOOLS, SHOW_ALERT } from "./type"
import _ from 'lodash'

export const fetchRecords = (options, callback, showLoader = true) => async (dispatch, store) => {
    try {
        let key = convertNormalOptions(options)
        if(store().records && store().records[options.routeType] && store().records[options.routeType][key]) {
            let data = _.map(store().records[options.routeType][key])
            if(options.routeType === CONSTANTS.routeType.STORE && store().records[options.routeType][key].id) {
                data = store().records[options.routeType][key]
            }
            return callback({data})
        }
        if(showLoader) {
            dispatch({
                type: LOADING
            })
        }
        let queryParams = {}
        if(options.routeType === CONSTANTS.routeType.EMPLOYEE) {
            if(options.operationType === CONSTANTS.operation.LIST) {
                queryParams.storeId = options.resourceId
            }
            if(options.operationType === CONSTANTS.operation.SEARCH) {
                queryParams.search = options.search
            }
        }
        if(options.routeType === CONSTANTS.routeType.STORE || options.routeType === CONSTANTS.routeType.TRANSACTION) {
            queryParams.storeId = options.resourceId
        }
        if(options.pageNumber && options.createdAtFirstItem) {
            queryParams = {...queryParams, pageNumber: options.pageNumber, createdAtFirstItem: options.createdAtFirstItem}
        }
        queryParams = queryParamsToString(queryParams)

        let apiInstance = new ApiBuilder(`${CONSTANTS.url[options.routeType][options.operationType]}${!_.isEmpty(queryParams) ? "?" + queryParams : ""}`, {
            headers: {
                Authorization: store().auth.token
            }
        })
        let responsePayload = await apiInstance.fetchOneOrAll()
        dispatch({
            type: SET_RECORDS,
            subtype: options.routeType,
            payload : {
                type: key,
                data: responsePayload.data
            }
        })
        return callback(responsePayload)
    }
    catch(error) {
        console.error("Fetch Records: ", error)
        dispatch({
            type: SHOW_ALERT,
            variant: "error",
            message: error.renderMessage || error.globalMessage || "Some error occurred, please try again"
        })
        return callback({
            hasError: true
        })
    }
}

export const postRecords = (type, payload, callback, showLoader = true) => async (dispatch, store) => {
    try {
        if(showLoader) {
            dispatch({
                type: LOADING
            })
        }
        let patchRequest = false
        let slug = CONSTANTS.url[payload.routeType][payload.operationType]
        let customPayload = {}
        if(payload.routeType === CONSTANTS.routeType.EMPLOYEE && [CONSTANTS.operation.ADD, CONSTANTS.operation.EDIT, CONSTANTS.operation.REMOVE].indexOf(payload.operationType) > -1) {
            customPayload = {
                userId: payload.id,
                storeId: payload.resourceId
            }
            if(payload.operationType !== CONSTANTS.operation.REMOVE) {
                customPayload.jobDesignationCode = payload.jobDesignationCode
            }
            if(payload.operation === CONSTANTS.operation.EDIT) {
                patchRequest = true
            }
        }
        if(payload.routeType === CONSTANTS.routeType.PROFILE) {
            patchRequest = true
            if(payload.operationType === 1) {
                let STRIPE_KEY = CONSTANTS.stripe_key;
                // const STRIPE_KEY = store().records.constants.stripe_key
                if (!STRIPE_KEY) {
                    dispatch({
                        type: SHOW_ALERT,
                        variant: "error",
                        message: "Stripe Key missing, please contact administrator or Refresh & try again"
                    })
                    return callback({
                        hasError: true,
                        message: "Stripe Key missing, please contact administrator or Refresh & try again"
                    })
                }
                let stripe = window.Stripe(STRIPE_KEY);
                let stripeResponse = await stripe.createToken('bank_account', {
                    country: "US",
                    currency: "usd",
                    account_holder_type: payload.account_holder_type,
                    account_holder_name: payload.account_holder_name,
                    account_number: payload.account_number,
                    routing_number: payload.routing_number
                })
                if(stripeResponse.error) {
                    dispatch({
                        type: SHOW_ALERT,
                        message: `Error: ${capitalize(stripeResponse.error.code)}`
                    })
                    return callback({
                        hasError: true,
                        ...stripeResponse
                    })
                }
                customPayload.bankAccountStripeTokenId = stripeResponse.token && stripeResponse.token.id ? stripeResponse.token.id : ""
            }
            else {
                customPayload = _.omit(payload, ["routeType", "operationType"])
            }
        }
        if(payload.routeType === CONSTANTS.routeType.STORE) {
            patchRequest = true
            slug = slug.replace(":storeId", payload.resourceId)
            if(payload.operationType === 1) {
                let STRIPE_KEY = CONSTANTS.stripe_key;
                // const STRIPE_KEY = store().records.constants.stripe_key
                if (!STRIPE_KEY) {
                    dispatch({
                        type: SHOW_ALERT,
                        variant: "error",
                        message: "Stripe Key missing, please contact administrator or Refresh & try again"
                    })
                    return callback({
                        hasError: true,
                        message: "Stripe Key missing, please contact administrator or Refresh & try again"
                    })
                }

                let stripe = window.Stripe(STRIPE_KEY);
                let stripeResponse = await stripe.createToken('bank_account', {
                    country: "US",
                    currency: "usd",
                    account_holder_type: payload.account_holder_type,
                    account_holder_name: payload.account_holder_name,
                    account_number: payload.account_number,
                    routing_number: payload.routing_number
                })
                if(stripeResponse.error) {
                    dispatch({
                        type: SHOW_ALERT,
                        message: `Error: ${capitalize(stripeResponse.error.code)}`
                    })
                    return callback({
                        hasError: true,
                        ...stripeResponse
                    })
                }

                customPayload.bankAccountStripeTokenId = stripeResponse.token && stripeResponse.token.id ? stripeResponse.token.id : ""
            }
            else {
                customPayload = _.omit(payload, ["routeType", "operationType", "resourceId"])
            }
        }

        let apiInstance = new ApiBuilder(slug, {
            headers: {
                Authorization: store().auth.token
            }
        })
        let responsePayload = {}
        if(patchRequest) {
            responsePayload = await apiInstance.patch({
                ...customPayload
            })
        }
        else {
            responsePayload = await apiInstance.post({
                ...customPayload
            })
        }
        dispatch({
            type: SHOW_ALERT,
            ...messageGenerator(payload.operationType)
        })
        if(payload.routeType === CONSTANTS.routeType.PROFILE) {
            let stripePayload = {}
            if(responsePayload.data && responsePayload.data && responsePayload.data.stripeConnectAccountRequirementsAndBankDetails) {
                stripePayload = responsePayload.data.stripeConnectAccountRequirementsAndBankDetails
            }
            dispatch({
                type: SET_AUTH_SESSION,
                payload: {
                    ...store().auth,
                    user: {
                        ...store().auth.user,
                        ...responsePayload.data,
                        ...stripePayload
                    }
                }
            })
        }
        if(payload.routeType === CONSTANTS.routeType.STORE) {
            let storeLoad = {}
            Object.keys(store().records.store).map(e => {
                if(_.includes(e, payload.resourceId)) {
                    storeLoad[e] = {
                        ...store().records.store[e],
                        ...responsePayload.data
                    }
                }
            })
            dispatch({
                type: SET_RECORDS,
                payload: {
                    type: payload.routeType,
                    data: storeLoad
                }
            })
            dispatch({
                type: RESET_RECORDS,
                payload: [CONSTANTS.routeType.HOME]
            })
        }
        if(payload.routeType === CONSTANTS.routeType.EMPLOYEE) {
            dispatch({
                type: RESET_RECORDS,
                payload: [CONSTANTS.routeType.EMPLOYEE]
            })
        }
        return callback(responsePayload)
    }
    catch(error) {
        console.error("Post Records: ", error)
        dispatch({
            type: SHOW_ALERT,
            variant: "error",
            message: error.renderMessage || error.globalMessage || "Some error occurred, please try again"
        })
        return callback({
            hasError: true
        })
    }
}

export const triggerModal = (options = { show: true, type: "", subtype: "", hide: true, data: "", ref: {} }) => async dispatch => {
    if (options.show === false || _.isEmpty(options)) {
        dispatch({
            type: RESET_MODAL
        })
        return;
    }
    if(options && !_.isEmpty(options)) {
        dispatch({
            type: SET_TOOLS,
            payload: {
                ...options
            }
        })
    }
}

export const setEditRecord = (type = undefined, callback) => async (dispatch, store) => {
    if(type) {
        let value = type === 1 ? true : false
        if(store().records['BACK_ALERT'] && value === store().records['BACK_ALERT'].value) return callback()
        if((_.isEmpty(store().records['BACK_ALERT']) || _.isUndefined(store().records['BACK_ALERT'])) && value === false) return callback()
        dispatch({
            type: SET_RECORDS,
            payload: {
                type: "BACK_ALERT",
                data: {value}
            }
        })
        return callback()
    }
    return callback(store().records['BACK_ALERT'])
}

export const createEditStore = (options, callback) => async (dispatch, store) => {
    try {
        dispatch({
            type: LOADING
        })

        let STRIPE_KEY = CONSTANTS.stripe_key;
        // const STRIPE_KEY = store().records.constants.stripe_key
        if(!STRIPE_KEY) {
            dispatch({
                type: SHOW_ALERT,
                variant: "error",
                message: "Stripe Key missing, please contact administrator or Refresh & try again"
            })
            return callback({
                hasError: true,
                message: "Stripe Key missing, please contact administrator or Refresh & try again"
            })
        }
        let stripe = window.Stripe(STRIPE_KEY);
        let stripeResponse = await stripe.createToken('bank_account', {
            country: "US",
            currency: "usd",
            account_holder_type: options.account_holder_type,
            account_holder_name: options.account_holder_name,
            account_number: options.account_number,
            routing_number: options.routing_number
        })

        if(stripeResponse.error) {
            dispatch({
                type: SHOW_ALERT,
                message: `Error: ${capitalize(stripeResponse.error.code)}`
            })
            return callback({
                hasError: true,
                ...stripeResponse
            })
        }
        let customPayload = _.omit(options, ["account_holder_name", "account_holder_type", "account_number", "confirm_account_number", "routing_number", "confirm_routing_number", "paymentDetailsSameAsOrganisation", "locationSearch"])
        if(options.isAutofillCompanyDetailsFromParentOrganisation) {
            customPayload = _.omit(customPayload, ["companyName", "companyDescription", "taxIdentificationCode"])
            if(!customPayload.isVirtualStore) {
                customPayload = _.omit(customPayload, ["url"])
            }
        }

        if(!customPayload.isVirtualStore) {
            customPayload.locationCode = store().records?.constants ? _.find(store().records.constants.listLocations, { state: options.state })['code'] : ""
            customPayload.locationCoordinates = {
                "type": "Point",
                "coordinates": options.locationCoordinates.coordinates
            }
            customPayload = _.omit(customPayload, ["state"])
        }
        
        customPayload.bankAccountStripeTokenId = stripeResponse.token && stripeResponse.token.id ? stripeResponse.token.id : ""

        let apiInstance = new ApiBuilder(`store/create`, {
            headers: {
                Authorization: store().auth.token || ""
            }
        })

        let responsePayload = await apiInstance.post({...customPayload})
        dispatch({
            type: SHOW_ALERT,
            variant: "success",
            message: `${options.isVirtualStore ? "Virtual" : "Physical"} Store Created Successfully`
        })
        dispatch({
            type: RESET_RECORDS,
            payload: ['home']
        })
        return callback(responsePayload)
    } 
    catch (e) {
        console.log(e); 
        dispatch({
            type: SHOW_ALERT,
            message: e.renderMessage || e.globalMessage || "Some error occurred, please try again"
        })
        return callback(e)
    }
}

const messageGenerator = type => {
    let message = "Operation Successful"
    let variant = "default"
    switch(type) {
        case CONSTANTS.operation.ADD: {
            message = "Added Successfully"
            variant = "success"
            break
        }
        case CONSTANTS.operation.EDIT: {
            message = "Updated Successfully"
            variant = "success"
            break;
        }
        case CONSTANTS.operation.REMOVE: {
            message = "Removed Successfully"
            variant = "success"
            break;
        }
        default: {}
    }
    return {message, variant}
}